import getDeviceType from 'utils/getDeviceType';

import { apiFarmService } from './apiService';

export const postDevices = (leadId: string, deviceToken: string) =>
  apiFarmService.post('/leads/devices', {
    lead_id: leadId,
    device_token: deviceToken,
    device_type: getDeviceType()
  });
