import { apiFarmService } from '@new-efarm/api/apiService';

import * as api from './skeleton';

const instance = api.instanceWithoutToken;
const request = api.request;
const instanceCustomHeaders = api.instanceCustomHeaders;

export const signin = (tempToken) => {
  const headers = {
    Authorization: `Bearer ${tempToken}`
  };
  return request(() => instanceCustomHeaders('post', '/users/signin', headers));
};

export const completeSignup = (payload) =>
  apiFarmService.post('/users/complete-signup', payload);

export const editProfile = (payload) => {
  return apiFarmService.put('/users/update', payload);
};

export const signup = (payload) => {
  return request(() =>
    instance.post('/users/signup', payload, { withCredentials: true })
  );
};
