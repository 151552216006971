import { useCallback, useMemo, useState, useContext } from 'react';
import Firebase from 'firebase/compat/app';
import { getToken, deleteToken } from 'firebase/messaging';
import { getMessaging } from 'firebase/messaging/sw';

import { SnackbarContext } from 'contexts/SnackbarContext';

import { postDevices } from '@new-efarm/api/notification';
import { FIREBASE } from '@new-efarm/enums/config-env';
import { useAuth } from '@new-efarm/contexts/useAuthContext';

export const firebase = Firebase.initializeApp(FIREBASE);
export const messaging = getMessaging(firebase);

export const useNotification = () => {
  const {
    isNotificationActive,
    setIsNotificationActive,
    hasFirstSetNotification,
    setHasFirstSetNotification
  } = useAuth();

  const { onDisplay: onDisplaySnackbar } = useContext(SnackbarContext);
  const [isLoadingToggleNotification, setIsLoadingToggleNotification] =
    useState(false);

  const notification = useMemo(
    () => ({
      isSupported: 'serviceWorker' in navigator && 'PushManager' in window,
      isGranted: Notification.permission === 'granted',
      isDenied: Notification.permission === 'denied',
      isNotSet:
        Notification.permission !== 'granted' &&
        Notification.permission !== 'denied',
      isActive: isNotificationActive,
      hasFirstSetNotification: hasFirstSetNotification
    }),
    [isNotificationActive, hasFirstSetNotification]
  );

  const requestPermission = useCallback((onGranted: () => void) => {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        onGranted();
      }
    });
  }, []);

  const storeFirebaseToken = useCallback(
    async (lead_id) => {
      try {
        setIsLoadingToggleNotification(true);
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY
        });
        if (token) {
          await postDevices(lead_id, token);
          setIsNotificationActive(true);
          onDisplaySnackbar('Berhasil mengaktifkan notifikasi');

          if (!notification.hasFirstSetNotification) {
            setHasFirstSetNotification(true);
          }
        }
      } catch (err) {
        //error
      } finally {
        setIsLoadingToggleNotification(false);
      }
    },
    [
      setIsNotificationActive,
      notification.hasFirstSetNotification,
      setHasFirstSetNotification,
      onDisplaySnackbar
    ]
  );

  const removeFirebaseToken = useCallback(async () => {
    try {
      setIsLoadingToggleNotification(true);
      await deleteToken(messaging);

      setIsNotificationActive(false);
      onDisplaySnackbar('Notifikasi dinonaktifkan');
    } catch (err) {
      //
    } finally {
      setIsLoadingToggleNotification(false);
    }
  }, [setIsNotificationActive, onDisplaySnackbar]);

  return {
    notification,
    storeFirebaseToken,
    requestPermission,
    removeFirebaseToken,
    isLoadingToggleNotification
  };
};
