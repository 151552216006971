import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import 'dayjs/locale/id';

dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.locale('id');

type IDate = string | number | Date | dayjs.Dayjs | null;

export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DEFAULT_DATE_FORMAT = 'DD MMMM YYYY';
export const BACK_END_DATE_FORMAT = 'YYYY-MM-DD';

export function now(format: string = DATE_TIME_FORMAT): string {
  return dayjs().format(format);
}

export function fromNow(date?: IDate): string {
  return dayjs(date).fromNow();
}

export function transformToDefaultFormat(date?: IDate): string {
  return dayjs(date).format(DEFAULT_DATE_FORMAT);
}

export function transformToISOFormat(date?: IDate): string {
  return dayjs(date).toISOString();
}

/**
 * Return the date in ISO8601 with current time.
 * @param date The date
 * @returns The date in ISO8601 with current time
 *
 * @example
 * ```
 * const actionTime = transformToISOWithCurrentTimeFormat(new Date());
 * console.log(actionTime);
 * // 2022-09-29T15:54:16+07:00
 * ```
 */
export function transformToISOWithCurrentTimeFormat(date?: IDate): string {
  if (!date) return dayjs().format();

  const startOfDay = dayjs(date).startOf('day');

  return dayjs(startOfDay)
    .add(
      dayjs.duration({
        hours: dayjs().hour(),
        minutes: dayjs().minute(),
        seconds: dayjs().second()
      })
    )
    .format();
}

export function transformToUTC(date?: IDate): string {
  return dayjs(date).utc().local().format();
}

export function transformToBackEndFormat(date?: IDate): string {
  return dayjs(date).format(BACK_END_DATE_FORMAT);
}

export function convertStartDate(date?: IDate): string {
  return dayjs(date).locale('id').format('dddd, D MMMM YYYY • HH:mm');
}

export function convertEndDate(date?: IDate): string {
  return dayjs(date).locale('id').format('HH:mm');
}
