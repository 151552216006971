import { useState, createContext, FC, ReactNode, useCallback } from 'react';
import { node } from 'prop-types';

import { Snackbar } from 'components/reusable/snackbar';

interface SnackbarProviderProps {
  children: ReactNode;
}

interface SnackbarContext {
  isDisplayed: boolean;
  message: string;
  isHideConfirmButton: boolean;
  onDisplay(message?: string, isConfirmAble?: boolean): void;
  onClose(): void;
  onConfirm(): void;
  confirmStateClicked: number;
}

export const defaultValuesSnackbarContext = {
  message: '',
  isDisplayed: false,
  isHideConfirmButton: true,
  onDisplay: () => {},
  onClose: () => {},
  onConfirm: () => {},
  confirmStateClicked: 0
};

export const SnackbarContext = createContext<SnackbarContext>(
  defaultValuesSnackbarContext
);

let windowTimeout: ReturnType<typeof setTimeout>;

export const SnackbarProvider: FC<SnackbarProviderProps> = ({ children }) => {
  const [message, setMessage] = useState('');
  const [isDisplayed, setIsDisplayed] = useState(false);
  const [isHideConfirmButton, setHideConfirmButton] = useState(true);
  const [confirmStateClicked, setConfirmStateClicked] = useState(0);

  const handleClose = useCallback(() => {
    setIsDisplayed(false);
    setHideConfirmButton(true);

    clearTimeout(windowTimeout);
  }, []);

  const handleDisplay = useCallback(
    (message: string, isHideConfirmButton = true) => {
      setMessage(message);
      setIsDisplayed(true);
      setHideConfirmButton(isHideConfirmButton);

      if (isHideConfirmButton) {
        windowTimeout = setTimeout(() => {
          handleClose();
        }, 2000);
      }
    },
    [handleClose]
  );

  function handleConfirm(): void {
    handleClose();
    setConfirmStateClicked((prevState) => prevState + 1);
  }

  return (
    <SnackbarContext.Provider
      value={{
        confirmStateClicked,
        isHideConfirmButton,
        isDisplayed,
        message,
        onClose: handleClose,
        onDisplay: handleDisplay,
        onConfirm: handleConfirm
      }}
    >
      {children}
      <Snackbar hideCloseButton />
    </SnackbarContext.Provider>
  );
};

SnackbarProvider.propTypes = {
  children: node.isRequired
};
