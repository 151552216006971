import {
  createContext,
  ReactNode,
  useCallback,
  useState,
  useContext,
  useEffect
} from 'react';

import {
  loadPersistedTokens,
  notification,
  hasFisrtSetNotification as hasFisrtSetNotificationStore
} from 'store/auth';
import { setTokens } from 'libs/efiauth';

interface ValueAuthContext {
  setToken: (token: string, refreshToken: string) => void;
  token: Partial<{
    access_token: string;
    refresh_token: string;
  }>;
  clearLocalStorage: () => void;
  setTokenInternal: (token: string) => void;
  tokenInternal: string;
  isNotificationActive: boolean;
  setIsNotificationActive: React.Dispatch<React.SetStateAction<boolean>>;
  hasFirstSetNotification: boolean;
  setHasFirstSetNotification: React.Dispatch<React.SetStateAction<boolean>>;
}

const AuthContext = createContext<Partial<ValueAuthContext>>({});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [token, setToken] = useState(loadPersistedTokens() ?? {});

  const [tokenInternal, setTokenInternal] = useState('');
  useEffect(() => {
    window.internalToken = tokenInternal;
  }, [tokenInternal]);

  const [isNotificationActive, setIsNotificationActive] = useState(
    notification.get()
  );
  useEffect(() => {
    notification.set(isNotificationActive);
  }, [isNotificationActive]);

  const [hasFirstSetNotification, setHasFirstSetNotification] = useState(
    hasFisrtSetNotificationStore.get()
  );
  useEffect(() => {
    hasFisrtSetNotificationStore.set(hasFirstSetNotification);
  }, [hasFirstSetNotification]);

  const handleSetToken = useCallback((token: string, refreshToken: string) => {
    setTokens(token, refreshToken);
    setToken({
      access_token: token,
      refresh_token: refreshToken
    });
  }, []);

  const clearLocalStorage = useCallback(() => {
    localStorage.clear();
    setToken({});
    setTokenInternal('');
  }, []);

  return (
    <AuthContext.Provider
      value={{
        setToken: handleSetToken,
        token,
        clearLocalStorage,
        setTokenInternal,
        tokenInternal,
        isNotificationActive,
        setIsNotificationActive,
        hasFirstSetNotification,
        setHasFirstSetNotification
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext) as ValueAuthContext;
