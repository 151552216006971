export const FIREBASE = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY ?? 'null',
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ?? 'null',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID ?? 'null',
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ?? 'null',
  messagingSenderId:
    process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID ?? 'null',
  appId: process.env.REACT_APP_FIREBASE_APP_ID ?? 'null',
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID ?? 'null'
};
