import axios from 'axios';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { createContainer } from 'unstated-next';
import {
  trackUserLogin,
  trackSignUp,
  trackUpdateData,
  trackAlreadyLoggedIn
} from '@auth/tracking';
import * as efiauth from 'libs/efiauth';
import { fetchLeadProfile } from 'api/backend';
import { signup, completeSignup, editProfile, signin } from '@auth/api/users';
import {
  loadPersistedName,
  storePersistedName,
  loadPersistedTokens
} from 'store/auth';
import {
  deleteDummyState,
  deleteAcquisitionState,
  setDummyState
} from 'store/acquisition';
import { getLeadsState, setLeadsState } from 'store/leads';
import { setLocationState } from 'store/location';
import {
  clevertapEventUserLogin,
  clevertapEventUpdateProfile
} from 'libs/analytics';
import { defaultValuesFlagsContext } from 'libs/flags';
import { getQueryParams } from 'libs/helpers';
import { useCookies } from 'react-cookie';
import { isNotifBlocked, isSubscribeCT } from 'utils';
import { getFirebaseToken } from 'libs/firebase';
import { isPushNotificationEnabled } from 'libs/notification';

const useAuth = (initialState = null) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [error, setError] = useState(null);
  const [tempIsLogged, setTempIsLogged] = useState(false);
  const [user, setUser] = useState(initialState);
  const [greetingPopup, setGreetingPopup] = useState(false);

  const [, , removeCookie] = useCookies([isNotifBlocked, isSubscribeCT]);

  const [isFetchLeadLoading, setIsFetchLeadLoading] = useState(false);

  const { isRefreshTokenEnabled } = defaultValuesFlagsContext;

  const isLoggedIn = efiauth.isLoggedIn || tempIsLogged;

  const setNameFromStorage = () => {
    const persistedName = loadPersistedName();
    if (persistedName) {
      setUser({
        ...user,
        name: persistedName
      });
    }
  };

  const setLeadsFromStorage = () => {
    const leadState = getLeadsState();
    if (leadState) {
      setUser({
        ...user,
        ...leadState
      });
    }
  };

  useEffect(() => {
    (async () => {
      const query = getQueryParams();
      try {
        if (query?.tokenExp || query?.refreshExp) {
          efiauth.setTokenExpiryTime(query.tokenExp, query.refreshExp);
          setTempIsLogged(true);
        }
        if (isRefreshTokenEnabled) {
          // removePersistedTokens();
        } else {
          const tokens = loadPersistedTokens();
          if (!tokens) {
            throw new efiauth.InvalidTokenError('not logged in');
          }

          efiauth.setAccessToken(tokens.access_token);
          efiauth.setRefreshToken(tokens.refresh_token);
        }
        if (isLoggedIn) {
          // const newUser = await fetchUserProfile();
          // setUser(newUser);
          // setIsInitialized(true);
        }
      } catch (err) {
        if (err instanceof efiauth.InvalidTokenError) {
          // not an error, it means user is not logged in
        } else {
          setError(err);
        }
      } finally {
        setNameFromStorage();
        setLeadsFromStorage();
      }
    })();
  }, [isLoggedIn]);

  const login = async (username, password) => {
    // WIP Refactor remove this after BE proxy
    let accessToken;
    let accessTokenExpiryTime;
    let refreshTokenExpiryTime;
    const authServiceUrl = process.env.REACT_APP_BACKEND_URL;
    try {
      const getTempToken = await axios.post(`${authServiceUrl}/app/login`, {
        username,
        password
      });
      const tempToken = getTempToken.data.data.app_token;
      const signinResponse = await signin(tempToken);
      accessToken = signinResponse.token;
      accessTokenExpiryTime = signinResponse.token_expired;
      refreshTokenExpiryTime = signinResponse.refresh_expired;
    } catch (err) {
      if (err?.response?.data?.message) {
        err.message = `${err?.response?.data?.message} (${err.message})`;
      }
      throw err;
    }
    efiauth.setTokens(accessToken, 'disabled');
    efiauth.setTokenExpiryTime(accessTokenExpiryTime, refreshTokenExpiryTime);
    const newUser = await fetchUserProfile();
    if (isPushNotificationEnabled() === 'granted') {
      getFirebaseToken(newUser.lead_id);
    }
    storePersistedName(newUser.name);
    trackUserLogin(username, newUser.lead_id, newUser.name);
    setUser(newUser);
    // delete acquisiton related state
    deleteDummyState();
    deleteAcquisitionState();
  };

  const loginByPhone = async (
    accessToken,
    phoneNumber,
    accessTokenExpiryTime,
    refreshTokenExpiryTime
  ) => {
    efiauth.setTokens(accessToken);
    efiauth.setTokenExpiryTime(accessTokenExpiryTime, refreshTokenExpiryTime);

    const newUser = await fetchUserProfile();
    if (isPushNotificationEnabled() === 'granted') {
      getFirebaseToken(newUser.lead_id);
    }

    storePersistedName(newUser.name);
    trackUserLogin(phoneNumber, newUser.lead_id, newUser.name);
    setUser(newUser);
  };

  const acquireUser = async (payload) => {
    try {
      await completeSignup(payload);
      // WIP REFACTOR handle non farmers analytics user props
    } catch (err) {
      if (err?.response?.data?.message) {
        err.message = `${err?.response?.data?.message} (${err.message})`;
      }
      throw err;
    }
  };

  const editUser = async (payload) => {
    try {
      const res = await editProfile(payload);
      const { data } = res?.data ?? {};
      // WIP REFACTOR handle non farmers analytics user props
      trackUpdateData(data.leads?.id, data.leads?.name);
      clevertapEventUpdateProfile({
        name: payload?.name || '',
        position: payload?.position || '',
        province: payload?.locations.province_name || '',
        city: payload?.locations.city_name || '',
        district: payload?.locations.district_name || '',
        subDistrict: payload?.locations.subdistrict_name || ''
      });
    } catch (err) {
      if (err?.response?.data?.message) {
        err.message = `${err?.response?.data?.message} (${err.message})`;
      }
      throw err;
    }
  };

  const signUp = async (payload) => {
    let accessToken;
    let accessTokenExpiryTime;
    let refreshTokenExpiryTime;
    try {
      const res = await signup(payload);
      accessToken = res.token;
      accessTokenExpiryTime = res.token_expired;
      refreshTokenExpiryTime = res.refresh_expired;
      trackSignUp(payload.phone, payload);
    } catch (err) {
      if (err?.response?.data?.message) {
        err.message = `${err?.response?.data?.message} (${err.message})`;
      }
      throw err;
    }
    efiauth.setTokens(accessToken, 'disabled');
    efiauth.setTokenExpiryTime(accessTokenExpiryTime, refreshTokenExpiryTime);
    const newUser = await fetchUserProfile();
    if (isPushNotificationEnabled() === 'granted') {
      getFirebaseToken(newUser.lead_id);
    }
    setDummyState();
    storePersistedName('Hai, Pembudidaya!');
    setUser(newUser);
  };

  const logout = () => {
    efiauth.doLogout();
    removeCookie(isNotifBlocked);
    removeCookie(isSubscribeCT);
    setUser(null);
  };

  // WIP REFACTOR to flag from BE
  const isFarmer = user?.is_farmer;
  const isFarmerRegistered = user?.name !== null;

  const isGuestMode = useMemo(() => {
    return !isFetchLeadLoading && !user;
  }, [user, isFetchLeadLoading]);

  const fetchUserProfile = async () => {
    setIsFetchLeadLoading(true);
    try {
      const leads = await fetchLeadProfile();
      const location = leads.location;
      const leadId = leads?.lead_id;
      const leadName = leads?.name;
      const leadPhone = leads?.phone;
      setLeadsState(leads);
      clevertapEventUserLogin({
        identity: leads.uuid || '',
        name: leads.name || '',
        phone: leads.phone || '',
        email: leads.email || '',
        verified: leads.is_verified,
        position: leads.position || '',
        province: leads.location.province_name || '',
        city: leads.location.city_name || '',
        district: leads.location.district_name || '',
        subDistrict: leads.location.subdistrict_name || '',
        cultivationType: leads.farm || ''
      });
      setLocationState({
        ...location,
        name: leads.address
      });
      if (leadId) trackAlreadyLoggedIn(leadId, leadName, leadPhone);
      return leads;
    } catch (err) {
      if (err?.response?.data?.message) {
        err.message = `${err?.response?.data?.message}`;
      }
      throw err;
    } finally {
      setIsFetchLeadLoading(false);
    }
  };

  const isUserInternal = !!user?.fbd;
  const isInternalMode = user?.fbd?.is_internal_mode;

  const setInternalModeUser = useCallback(
    (is_internal_mode) => {
      setUser({
        ...user,
        fbd: {
          ...user.fbd,
          is_internal_mode
        }
      });
    },
    [user]
  );

  return {
    isFetchLeadLoading,
    isInitialized,
    error,
    user,
    login,
    logout,
    loginByPhone,
    isLoggedIn,
    acquireUser,
    editUser,
    signUp,
    isFarmer,
    isFarmerRegistered,
    isGuestMode,
    isUserInternal,
    isInternalMode,
    greetingPopup,
    setGreetingPopup,
    setInternalModeUser
  };
};

const AuthContext = createContainer(useAuth);
export default AuthContext;
