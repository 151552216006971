import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import LoadingScreen from 'pages/LoadingScreen';
import App from 'components/App';
import * as efiauth from 'libs/efiauth';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from 'reportWebVitals';
import { disableTargeting } from 'libs/survicate';
import CacheBuster from 'react-cache-buster';
import packageJson from '../package.json';
import classNames from 'classnames';
import sentry from 'libs/errorMonitoring';

import './styles.css';

// Polyfill
import 'core-js/es/reflect/own-keys';
import 'core-js/es/object/values';
import 'core-js/es/object/from-entries';
import 'core-js/es/object/entries';
import 'core-js/es/object/keys';
import 'core-js/es/array/includes';
import 'promise-polyfill';
import 'proxy-polyfill';
import 'url-search-params-polyfill';

sentry();

let appVersion = packageJson.version;
window.appVersion = appVersion;
const isProduction = process.env.NODE_ENV === 'production';

// const isDownloadPromptVisible =
//   isAndroid() && isUsingBrowser() && isTrustedWebActivity() === false;

// const App = lazy(() => componentLoader(() => import('components/App')));

efiauth.initialize({
  authsUrl: process.env.REACT_APP_BACKEND_URL
});

// Survicate: Disable automatic targeting
disableTargeting();

ReactDOM.render(
  <CacheBuster
    currentVersion={appVersion}
    isEnabled={isProduction}
    isVerboseMode={false}
    loadingComponent={<LoadingScreen />}
  >
    <React.StrictMode>
      <Suspense fallback={<LoadingScreen />}>
        <div className={classNames('max-width position-relative h-100')}>
          <App />
          <div id="popup-portal" />
        </div>
      </Suspense>
    </React.StrictMode>
  </CacheBuster>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
