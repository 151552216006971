import axios from 'axios';
import { deleteToken } from 'firebase/messaging';

import { setTokens } from 'libs/efiauth';
import { loadPersistedTokens } from 'store/auth';

import { messaging } from '@new-efarm/hooks/useNotifcation';

const hostAuth = process.env.REACT_APP_AUTH_SERVICE_URL;

const createAxios = (baseURL?: string) => {
  const api = axios.create({
    baseURL
  });
  api.interceptors.request.use(
    (config) => {
      config.headers['X-Client-Id'] = 'EFARM';
      config.headers['Authorization'] = `Bearer ${
        window.internalToken || loadPersistedTokens()?.access_token
      }`;

      return config;
    },
    (error) => Promise.reject(error)
  );
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        const originalRequest = error.config;
        originalRequest._retry = true;

        axios
          .post(
            `${hostAuth}/auth/refresh`,
            {
              refresh_token: loadPersistedTokens()?.refresh_token
            },
            {
              headers: {
                'X-Client-Id': 'EFARM'
              }
            }
          )
          .then((res) => {
            setTokens(res.data.data.access_token, res.data.data.refresh_token);
            return api(originalRequest);
          })
          .catch(() => {
            deleteToken(messaging).finally(() => {
              localStorage.clear();
              window.location.replace('/');
            });
          });
      }

      return Promise.reject(error);
    }
  );

  return api;
};

export const apiFarmService = createAxios(process.env.REACT_APP_BACKEND_URL);

export const apiPatrickFarmService = createAxios(
  `${process.env.REACT_APP_PATRICK_API_URL}/farm`
);

export const apiCultivation = createAxios(
  process.env.REACT_APP_CULTIVATION_API_URL
);

export const apiGPT = createAxios(
  process.env.REACT_APP_CULTIVATION_GPT_API_URL
);

export const apiMarketPlace = createAxios(
  process.env.REACT_APP_MARKETPLACE_URL
);

export const apiAuthWithToken = createAxios(
  process.env.REACT_APP_AUTH_SERVICE_URL
);
